import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { Section, Button } from '../../Core';
import { navigate } from 'gatsby';
import directors from './../../../assets/image/rebrand/jpg/youfibre-directors.jpg'

const Contact = (props) => {

  return (
    <Section className="contact">
      <Container>
        <Row>
          <Col sm={12} lg={6}>
            <h2>
              THERE’S NO ONE BETTER TO HELP YOU THAN YOU.
            </h2>
            <p className="mt-50 mb-50">
              Please call on us anytime. And when we say call, we mean phone. We’ll actually answer.
              You can email too, or try one of those online chat things. No bots, talk with flesh
              and blood humans.
            </p>
            <div className="mb-50">
              <Button onClick={() => (typeof window !==  "undefined") && document.getElementsByClassName('YFChat')[0].click()} className="medium-button blue-bg">
                Chat&nbsp;Now
              </Button>
              <Button
                onClick={() => navigate('https://support.youfibre.com/knowledge-base')}
                className="medium-button dark-bg"
              >
                FAQ
              </Button>
            </div>
          </Col>
          <Col sm={12} lg={6}>
            <img src={directors} className="w-100 ratio-1"/>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default Contact;
