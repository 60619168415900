import React, { createRef, useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import CheckAvailability from './../../../components/Rebrand/CheckAvailability/CheckAvailability';
import youfibre_banner_01 from './../../../assets/image/rebrand/jpg/youfibre_01.jpg';
import youfibre_banner_02 from './../../../assets/image/rebrand/jpg/youfibre_02.jpg';
import youfibre_banner_03 from './../../../assets/image/rebrand/jpg/youfibre_03.jpg';
import youfibre_banner_04 from './../../../assets/image/rebrand/jpg/youfibre_04.jpg';
import TrustBoxWidget from '../../TrustBoxWidget/TrustBoxWidget';

import lottie from "lottie-web"
import rightArrow from "../../../assets/animations/large-arrow.json"
import smallArrow from "../../../assets/animations/small-arrow.json"
import snailAnimation from "../../../assets/animations/snail-animation.json"
import limitedTimeOffer from './../../../assets/image/rebrand/svg/limited-time-offer.svg';

import axios from 'axios';

import { Text, PageTitle, Section } from "../../Core";
import styled from "styled-components";
import { position } from 'polished';

import trustpilot from './../../../assets/image/rebrand/svg/trustpilot-green.svg';
import score2_5 from './../../../assets/image/rebrand/svg/stars/2-5.svg';
import score3 from './../../../assets/image/rebrand/svg/stars/3.svg';
import score3_5 from './../../../assets/image/rebrand/svg/stars/3-5.svg';
import score4 from './../../../assets/image/rebrand/svg/stars/4.svg';
import score4_5 from './../../../assets/image/rebrand/svg/stars/4-5.svg';
import score5 from './../../../assets/image/rebrand/svg/stars/5.svg';

const animationContainer = createRef()
const snailAnimationContainer = createRef()
const arrowContainer = createRef()

const SnailAnimationSection = styled.div`
  width: 2100px !important;
  @media (min-width: 2099px) {
    width: 2800px !important;
  }
`
const AbsoluteImage = styled.img`
  position: absolute;
  right: 100px;
  bottom: 0;
  width: 170px;
  @media (min-width: 991px) and (max-width: 1200px) { 
    right: 0;
  }
  @media (max-width: 991px) { 
    width: 130px;
    bottom: auto;
    top: 0;
    left: 15px;
  }
`

const RightArrow = styled.div`
  width: 215px;
  height: 307px;
  position: absolute;
  right: 135px;
  top: -80px;
  @media (max-width: 1320px) { 
    right: 70px;
  }
  @media (min-width: 991px) and (max-width: 1200px) { 
    right: -30px;
  }
  @media (max-width: 991px) { 
    width: 75px;
    height: 150px;
    transform: rotate(-45deg);
    top: -128px;
    right: calc(50% - 142px);
  }
`

const LeftArrow = styled.div`
  position: absolute;
  width: 230px;
  height: 280px;
  top: -40px;
  vertical-align: top;
  right: calc(50% + 255px);
  @media (max-width: 991px) { 
    display: none;
  }
`

const SubtitleWrapper = styled.div`
  max-width: 510px;
  margin: auto;
`

const StyledText = styled(Text)`
  @media (max-width: 1320px) { 
    font-size: 1.3rem !important;
  }
  @media (max-width: 991px) {
    text-align: center;
  }
`

const SmallText = styled.small`
  font-size: 10px !important;
  padding-top: 2px;
  font-weight: 300;
`

const HeroBlackFriday = (props) => {
  const [trustScore, setTrustScore] = useState(5);
  const [truspilotImage, setTrustpilotImage] = useState(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: rightArrow
    });
    lottie.loadAnimation({
      container: snailAnimationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: snailAnimation
    });
    lottie.loadAnimation({
      container: arrowContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: smallArrow
    });
    
  }, [])

  useEffect(() => {
    const businessUnitId = "5f4153095882480001d60ab4";
    axios.get(`https://widget.trustpilot.com/trustbox-data/53aa8912dec7e10d38f59f36?businessUnitId=${businessUnitId}&locale=en-GB&reviewLanguages=en&reviewStars=4%2C5&includeReviews=true&reviewsPerPage=6`)
        .then(function (response) {
            let data = response.data;
            setTrustScore(data.businessEntity.trustScore);
            setTrustpilotImage(renderTrustpilotImage(data.businessEntity.trustScore));
        })
        .catch(function (error) {
            console.log(error);
        });
  }, []);

  const renderTrustpilotImage = (score) => {
    if (score > 2.5 && score <= 3) { 
      return score2_5;
    } else if (score > 3 && score <= 3.5) {
      return score3;
    } else if (score > 3.5 && score <= 4) {
      return score3_5;
    } else if (score > 4 && score <= 4.5) {
      return score4;
    } else if (score > 4.5 && score <= 5) {
      return score4_5;
    } else if (score === 5) {
      return score5;
    }
  }

  return (
    <>
      <Section className="dark-bg pt-0">
        <Container className='position-relative'>
          <Row className="justify-content-md-center animations-wrapper black-friday-animations">
            <Col sm="12" className='text-center'>
              <h1 className="whiteColor small-title black-friday-small-title">
                Black friday?
              </h1>
              <h1 className='green-cl large-title position-relative'>BRILLIANT VALUE<RightArrow ref={animationContainer} /></h1>
              <SubtitleWrapper>
                <StyledText
                  color="dark"
                  fontWeight={700}
                  fontSize="1.5rem"
                  lineHeight="1.25"
                  marginTop="1rem"
                ><b>Get 1000 Mbps, Full-Fibre broadband now just £29.99/month. Every day, including Fridays.</b></StyledText>
              </SubtitleWrapper>
              <Row>
                <Col className='mt-xl-4 pl-0 pr-0 text-center centered'>
                  <LeftArrow ref={arrowContainer}/>
                  <CheckAvailability
                    focusInput={false}
                    hideLogo={true}
                    customTitle={""}
                    customSubtitle={""}
                  />  
                </Col>
              </Row>
              <Row>
                <Col sm={12} className='flex justify-content-center'>
                  <img src={trustpilot} alt="Trustpilot" className="trustpilot-logo mt-xl-4" width={70}/>
                  <img src={truspilotImage} alt="Trustpilot" className="trustpilot-logo mt-xl-4 ml-2" width={110}/>
                  <SmallText className="mt-xl-4 d-block ml-2">Rated {trustScore} out of 5</SmallText>
                </Col>
              </Row>
            </Col>
          </Row>
          <AbsoluteImage src={limitedTimeOffer} />
        </Container>
      </Section>
      <SnailAnimationSection>
        <div
          id="animation-container"
          ref={snailAnimationContainer}
        />
      </SnailAnimationSection>
    </>
  );
};

export default HeroBlackFriday;
