import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Section } from "../../Core";
import image1 from './../../../assets/image/rebrand/png/youfibre_lifeline.png'
import icon1 from './../../../assets/image/rebrand/svg/youphone-icon.svg'
import icon2 from './../../../assets/image/rebrand/svg/youphoneplus-icon.svg'

const YouPhone = (props) => {
    return (
        <Section className="light-green-bg text-white youphone">
            <Container>
                <Row>
                    <Col sm={12}>
                        <h2>YOUPHONE. A LIFELINE FOR LANDLINES.</h2>
                    </Col>
                    <Col sm={12} lg={6}>
                        <img src={image1} className="w-100 light-green-bg img-fit ratio-1 pb-5"/>
                    </Col>
                    <Col sm={12} lg={6}>
                        <p className="mb-50">
                            The next generation of home phone.<br /><br />
                            Landlines. Does anyone use them these days? But YouPhone, well that’s a whole other story. For starters it’s VOIP - a Very Obviously Impressive Phone.<br /><br />
                            (Actually, it really means Voice Over Internet Protocol). Once you learn more, you’ll know it’s time to say goodbye to ye olde landline.
                        </p>
                        <hr />
                        <Row>
                            <Col xs={3} md={2} lg={2} className="flex justify-content-center align-items-center">
                                <img src={icon1} className="invert w-100" />
                            </Col>
                            <Col xs={6} md={7} lg={8} className="text-left justify-center">
                                <h5>YouPhone</h5>
                                <span>Free unlimited evening & weekend UK landline calls.</span>
                            </Col>
                            <Col xs={3} md={3} lg={2} className="text-left justify-center text-center">
                                <h5>£5</h5>
                                <small className="dark-color">per&nbsp;month</small>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col xs={3} md={2} lg={2} className="flex justify-content-center align-items-center">
                                <span className="notify-badge icoPlus dark-bg">+</span>
                                <img src={icon2} className="invert w-100" />
                            </Col>
                            <Col xs={6} md={7} lg={8} className="text-left justify-center">
                                <h5>YouPhone Plus</h5>
                                <span>Free unlimited UK landline calls - yep, all of 'em.</span>
                            </Col>
                            <Col xs={3} md={3} lg={2} className="text-left justify-center text-center">
                                <h5>£10</h5>
                                <small className="dark-color">per&nbsp;month</small>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col xs={3} md={2} lg={2} className="flex justify-content-center align-items-center">
                                <span className="notify-badge icoPro dark-bg">PRO</span>
                                <img src={icon2} className="invert w-100" />
                            </Col>
                            <Col xs={6} md={7} lg={8} className="text-left justify-center">
                                <h5>YouPhone Pro</h5>
                                <span>Unlimited calls to UK landline & mobiles, any time</span>
                            </Col>
                            <Col xs={3} md={3} lg={2} className="text-left justify-center text-center">
                                <h5>£15</h5>
                                <small className="dark-color">per&nbsp;month</small>
                            </Col>
                        </Row>
                        <hr />
                    </Col>
                </Row>
            </Container>
        </Section>
    )
};

export default YouPhone;
