import React, { createRef, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import CheckAvailability from '../CheckAvailability/CheckAvailability';
import desktopAnimation from "../../../assets/animations/desktop-new.json"
import snailAnimation from "../../../assets/animations/snail-animation.json"
import lottie from "lottie-web"
import styled from "styled-components";

import provider from "../../../assets/image/rebrand/png/provider-of-the-year.png"
import tpImg from "../../../assets/image/rebrand/png/tp.png"

const animationContainer = createRef()
const snailAnimationContainer = createRef()

const SnailAnimationSection = styled.div`
  width: 2100px !important;
  @media (min-width: 2099px) {
    width: 2800px !important;
  }
`

const SubText = styled.p`
  max-width: 770px;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: -0.4pt;
`

const HeroLandingCustomOffer = () => {

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: desktopAnimation
    });
    const anim1 = lottie.loadAnimation({
      container: snailAnimationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: snailAnimation
    });
  }, [])

  return <>
    <Col className="green-bg homepage pt-5 ">
      <Row className="justify-content-md-center">
        <Col sm={12} md={5}>
          <div
            id="animation-container"
            ref={animationContainer}
          />
        </Col>
      </Row>
      <Row className="justify-content-md-center mt-0">
        <Col lg={8} md={8} sm={12}>
          <SubText className="text-center largest-font pt-0">
            Join The Big Spring Switch to escape the mid-contract broadband price rises. <strong>Switch to YouFibre for Fixed Price Plans.</strong>
          </SubText>
        </Col>
      </Row>
      <Row className="justify-content-md-center mt-2 home-hero">
        <Col lg={6} md={8} sm={12}>
          <CheckAvailability center />
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col lg={5} md={12} sm={12} className="mb-3 mt-4 text-center mt-xs-0">
          <img src={tpImg} className='mb-3 ml-3 mr-3' width={250} />
          <img src={provider} className='mb-3 ml-3 mr-3' width={185}/>
        </Col>
      </Row>
      <br></br>
    </Col>
    <SnailAnimationSection>
      <div
        id="animation-container"
        ref={snailAnimationContainer}
      />
    </SnailAnimationSection>
  </>
};

export default HeroLandingCustomOffer;
